import { useAuth } from 'context/AuthContext';

function LoginButtons() {
  const { openLoginModal, openRegisterModal } = useAuth();

  return (
    <>
      <div className="btn-hover-outer">
        <button className="button-outlined sm" onClick={openLoginModal}>
          <span>Login</span>
        </button>
      </div>
      <div className="btn-hover-outer">
        <button className="button-contained sm" onClick={openRegisterModal}>
          Sign Up
        </button>
      </div>
    </>
  );
}

export default LoginButtons;
