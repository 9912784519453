import Topbar from './Topbar';

export default function Header({ pageProps }) {
  const categories = pageProps.pageProps.categories
    ? pageProps.pageProps.categories.Product_categories
    : [];
  const siteInfo = pageProps.pageProps.siteInfo;
  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Topbar categories={categories} siteInfo={siteInfo} />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
