import { useSession } from 'next-auth/react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import Authpopup from './Authpopup';

import MdcCloseCircleOutline from '@meronex/icons/mdc/MdcCloseCircleOutline';
import MobileMenu from './MobileMenu';
import Logo from './Logo';
import NavItem from './NavItem';
import React from 'react';
import { ReactSVG } from 'react-svg';
import MenuIcon from '@/images/menuicon.svg';

export default function Topbar({ categories }) {
  const { status } = useSession();
  const sortedCategories = categories.sort((a, b) => {
    if (a.sort === null) return 1;
    else return a.sort - b.sort;
  });

  const toggleMobileMenu = async () => {
    var toggleBox = document.getElementById('basic-navbar-nav');
    var mobilemenuoverlay = document.getElementById('mobilemenuoverlay');
    toggleBox.classList.toggle('open');
    mobilemenuoverlay.classList.toggle('open');
    if (document) document.body.classList.add('no-scroll');
  };

  const hideMobileMenu = async () => {
    var toggleBox = document.getElementById('basic-navbar-nav');
    var mobilemenuoverlay = document.getElementById('mobilemenuoverlay');
    toggleBox.classList.remove('open');
    mobilemenuoverlay.classList.remove('open');
    if (document) document.body.classList.remove('no-scroll');
  };

  return (
    <>
      <Navbar expand="lg">
        <Logo />

        <Button className="mobilemenutoggle" onClick={() => toggleMobileMenu()}>
          <ReactSVG src={MenuIcon.src} />
        </Button>

        <div id="mobilemenuoverlay" onClick={() => hideMobileMenu()}></div>

        <Navbar.Collapse id="basic-navbar-nav">
          <div id="mobilemenuclose" onClick={() => hideMobileMenu()}>
            <MdcCloseCircleOutline />
          </div>

          <Authpopup />

          {status === 'authenticated' && (
            <MobileMenu hideMobileMenu={hideMobileMenu} />
          )}

          <Nav className="m-auto">
            {sortedCategories.map((_category) => (
              <React.Fragment key={_category.cat_id}>
                {_category.show_on_top_nav && (
                  <NavItem
                    category={_category}
                    key={_category.cat_id}
                    hideMobileMenu={hideMobileMenu}
                  />
                )}
              </React.Fragment>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
