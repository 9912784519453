import Link from 'next/link';
import Image from 'next/image';
import crown from '@/images/crown.svg';
import crownhover from '@/images/crown-hover.svg';
import { useActiveSubscription } from '@/components/hooks/useActiveSubscription';
import { ReactSVG } from 'react-svg';

function UpgradeButton() {
  const { activeSubscription, loading } = useActiveSubscription();
  return (
    <>
      {!activeSubscription && !loading && (
        <Link href="/pro">
          <a className="tokgpro">
            <label>
              <span>
                <ReactSVG src={crown.src} />
              </span>
              <span>
                <ReactSVG src={crownhover.src} />
              </span>
              Upgrade
            </label>
          </a>
        </Link>
      )}
    </>
  );
}

export default UpgradeButton;
